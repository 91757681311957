<template>
  <div class="bar-chart svg-container" ref="barChart">
    <div class="mb-3 mt-2">
      <span
        class="fw-bold d-block d-sm-inline-block"
        :class="type === 'daily' ? 'fs-5' : 'fs-6'"
        >{{ title }}</span
      >
      <template v-if="type === 'daily'">
        <span
          class="pe-2 ps-sm-3 fw-bold d-inline-block"
          v-for="(item, key) in chartColor"
          :key="key"
        >
          <template v-if="key !== 'null'">
            <span
              class="d-inline-block"
              :style="{ color: item }"
              :class="key === 'notify' ? 'fs-4' : ''"
            >
              <svg
                v-if="key === 'notify'"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <polygon points="0,0 7,14 14,0" />
              </svg>
              <svg
                v-else-if="key === 'changePosture'"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <polygon points="0,0 7,14 14,0" />
              </svg>
              <svg
                v-else-if="key === 'nLyingTimeout'"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <polygon points="0,0 7,14 14,0" />
              </svg>
              <svg
                v-else-if="key === 'nLeaveBed'"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <polygon points="0,0 7,14 14,0" />
              </svg>
              <svg
                v-else-if="key === 'nLeaveTimeout'"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <polygon points="0,0 7,14 14,0" />
              </svg>
              <svg
                v-else-if="key === 'offLine'"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
                stroke="#333"
              >
                <polygon points="0,0 7,14 14,0" />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-circle-fill"
                viewBox="0 0 16 16"
              >
                <circle cx="7" cy="7" r="7" />
              </svg>
            </span>
            <span class="d-inline-block">{{
              $t('__' + key) + (key === 'notify' ? ':' : '')
            }}</span>
            <span v-if="key === 'nLyingTimeout'">{{
              '【' +
                $t('__LYBNotifyText') +
                '】'
            }}</span>
            <span v-if="key === 'nLeaveBed'">{{
              '【' +
                $t('__LVB') +
                '】'
            }}</span>
            <span v-if="key === 'nLeaveTimeout'">{{
              '【' +
                $t('__leaveTheBedTimeOut') +
                '】'
            }}</span>
          </template>
        </span>
      </template>
    </div>
    <div class="d-flex align-items-center flex-md-row flex-column">
      <div
        v-if="type !== 'daily'"
        :style="Number(viewport) > 576 ? 'width: 300px' : 'width: 100%'"
        :class="Number(viewport) > 576 ? '' : 'mb-3'"
      >
        <div class="p-1 d-flex justify-content-between align-items-center">
          <span class="">{{ $t('__nightTimeLeaveTheBed') + ' : ' }}</span>
          <span
            ><span class="fs-4 fw-bold">{{ statistics.nightLeave || 0 }}</span
            >{{ $t('__times') }}</span
          >
        </div>
        <div
          class="p-1 bg-light d-flex justify-content-between align-items-center"
        >
          <span class="">{{ $t('__nightTimeSleep') + ' : ' }}</span>
          <span
            ><span class="fs-4 fw-bold" :style="'color:' + chartColor.SLP">{{
              Math.floor(Number(statistics.nightSleep || 0) * 10) / 10
            }}</span>
            {{ $t('__hour') }}</span
          >
        </div>
        <div class="p-1 d-flex justify-content-between align-items-center">
          <span class="">
            {{ $t('__daytimeLeaveTheBed') + ' : ' }}
          </span>
          <span
            ><span class="fs-4 fw-bold">{{ statistics.dayLeave || 0 }}</span>
            {{ $t('__times') }}</span
          >
        </div>
        <div
          class="p-1 bg-light d-flex justify-content-between align-items-center"
        >
          <span class="">
            {{ $t('__daytimeOnBed') + ' : ' }}
          </span>
          <span>
            <span class="fs-4 fw-bold" :style="'color:' + chartColor.LYB">{{
              Math.floor(Number(statistics.dayOnBed || 0) * 10) / 10
            }}</span>
            {{ $t('__hour') }}
          </span>
        </div>
      </div>
      <div
        class="svg"
        :style="
          type !== 'daily' && Number(viewport) > 576
            ? 'width: calc(100% - 220px)'
            : ''
        "
      >
        <svg
          :width="svgWidth + svgPaddingX"
          :height="svgHeight + svgPaddingY"
          v-if="redrawToggle"
          streamable="true"
          version="1.2"
        >
          <!-- 圖表區塊 -->
          <g
            x="0"
            y="0"
            :transform="`translate(${svgPaddingX - 20},${svgPaddingY / 2})`"
            ref="rectSVG"
          >
            <rect
              v-for="(item, index) in data"
              :key="'statusData' + item[xKey] + index"
              class="bar-positive"
              :y="0"
              :height="svgHeight"
              :fill="chartColor[item[yKey]]"
            />
          </g>
          <!-- X軸區塊 -->
          <g
            x="0"
            y="0"
            ref="xAxis"
            :transform="
              `translate(${svgPaddingX - 20},${svgPaddingY / 2 + svgHeight})`
            "
          ></g>
          <!-- 滑鼠指向區塊 -->
          <g
            v-if="sliderDate !== null"
            x="0"
            y="0"
            :transform="`translate(${svgPaddingX - 20},0)`"
            style="pointer-events: none;"
          >
            <rect
              class="no-print"
              :y="0"
              width="6"
              :height="svgHeight + svgPaddingY"
              fill="rgba(0, 0, 0, .3)"
              stroke=""
              ref="mousemoveRect"
              transform="translate(-3,0)"
            />
          </g>
          <!-- 通知區塊 -->
          <g
            v-if="notify"
            x="0"
            y="0"
            :transform="`translate(${svgPaddingX - 20},0)`"
          >
            <polygon
              ref="notifyPolygon"
              v-for="(item, index) in notify"
              :key="'notify' + item[xKey] + index"
              :y="0"
              points="0,0 8,16 16,0"
              :fill="
                item.value === 'lyingTimeout' ? chartColor.nLyingTimeout :
                item.value === 'leaveBed' ? chartColor.nLeaveBed :
                item.value === 'leaveTimeout' ? chartColor.nLeaveTimeout :
                item.value === 'changePosture' ? chartColor.changePosture : chartColor.offLine
              "
              :opacity="item.value === 'changePosture' ? 0.5 : 1"
              :stroke="item.value === 'mqttStatus' ? '#333' : ''"
              :transform="`translate(-${polygonWidth / 2},0)`"
            />
          </g>
        </svg>
        <div id="tooltip" class="no-print">
          <div class="tooltip shadow rounded" ref="tooltip"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { select, selectAll, pointer } from 'd3-selection'
import { scaleTime } from 'd3-scale'
import { timeHour } from 'd3-time'
import { axisBottom } from 'd3-axis'
import { drag } from 'd3-drag'
import 'd3-transition'
import { mapMutations, mapState, mapGetters } from 'vuex'
import i18n from '@/lang/lang.js'

export default {
  name: 'BarChart',
  props: [
    'type',
    'startAt',
    'endAt',
    'title',
    'xKey',
    'yKey',
    'svgPaddingX',
    'svgPaddingY',
    'data',
    'notify',
    'chartColor',
    'repaint',
    'statistics'
  ],
  data () {
    return {
      chartData: this.data,
      svgWidth: 0,
      svgHeight: 23,
      redrawToggle: true,
      polygonWidth: 16,
      touchX: 0,
      mousemoveBedStatus: null,
      windowWidth: window.innerWidth,
      sliderDate: 0
    }
  },
  computed: {
    ...mapState(['viewport', 'timezone']),
    ...mapGetters(['notify_type_name']),
    xScale () {
      return scaleTime()
        .range([0, this.svgWidth])
        .domain([this.startAt, this.endAt])
    },
    xAxis () {
      const vm = this
      return axisBottom(vm.xScale)
        .ticks(timeHour.every(1))
        .tickFormat(function (d) {
          return vm.$getTimeZoneDate(
            new Date(d).getTime() / 1000,
            vm.timezone,
            'HH'
          )
        })
    },
    statusDataScale () {
      return scaleTime()
        .range([0, this.svgWidth])
        .domain([this.startAt, this.endAt])
    }
  },
  watch: {
    sliderDate () {
      this.mousemoveStarted()
    },
    repaint (data) {
      if (data) {
        this.repaintChart()
      }
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded']),
    mouseover () {
      select(this.$refs.tooltip).style('opacity', 1)
    },
    mousemoveNotify (event, data) {
      const vm = this
      select(vm.$refs.tooltip)
        .html(
          (data.value === 'mqttStatus' ? i18n.t('__notifyTypeOffline') : vm.notify_type_name[data.value]) +
            '(' +
            this.$getTimeZoneDate(
              new Date(data.time * 1000),
              this.timezone,
              'YYYY-MM-DD HH:mm'
            ) +
            ')'
        )
        .style('top', 'auto')
        .style(
          'left',
          (vm.svgWidth / 2 < pointer(event)[0]
            ? pointer(event)[0] - 140
            : pointer(event)[0] + vm.svgPaddingX) + 'px'
        )
        .style('bottom', pointer(event)[1] + 'px')
    },
    mousemoveBarChart (event, data) {
      const vm = this
      vm.sliderDate = data.time * 1000
      select(vm.$refs.tooltip)
        .html(
          (data.value ? i18n.t('__' + data.value) : i18n.t('__noDate')) +
            '(' +
            this.$getTimeZoneDate(
              new Date(data.time * 1000),
              this.timezone,
              'YYYY-MM-DD HH:mm'
            ) +
            ')'
        )
        .style(
          'left',
          (vm.svgWidth / 2 < pointer(event)[0]
            ? pointer(event)[0] - 140
            : pointer(event)[0] + vm.svgPaddingX) + 'px'
        )
        .style('top', pointer(event)[1] + 'px')
        .style('bottom', 'auto')
    },
    mouseleave () {
      select(this.$refs.tooltip).style('opacity', 0)
    },
    repaintChart () {
      this.$nextTick(function () {
        const width = Number(
          this.$refs.barChart.offsetWidth -
            this.svgPaddingX -
            (this.type === 'daily' ? 0 : 300)
        )
        this.svgWidth =
          width <= 1402 &&
          document.querySelector('body').className.indexOf('no-print') === -1
            ? this.type === 'daily'
              ? 1401
              : 1100
            : width
        this.animateLoad()
      })
    },
    animateLoad () {
      // const d3Timer = timer(elapsed => {
      select(this.$refs.barChart)
        .selectAll('rect')
        .data(this.data)
        .attr('x', (d, i) => {
          return this.xScale(new Date(d[this.xKey] * 1000))
        })
        .attr('width', (d, i) => {
          return i === this.data.length - 1
            ? 0 // this.svgWidth - this.xScale(new Date(d[this.xKey] * 1000))
            : this.xScale(new Date(this.data[i + 1][this.xKey] * 1000)) -
                this.xScale(new Date(d[this.xKey] * 1000)) +
                0.8
        })
        .on('mouseover', this.mouseover)
        .on('mousemove', this.mousemoveBarChart)
        .on('mouseleave', this.mouseleave)
        .on('scroll', this.scroll)
      //   if (elapsed > this.data.length) d3Timer.stop()
      // }, 1000)

      select(this.$refs.rectSVG).on('mouseleave', () => {
        const vm = this
        vm.sliderDate = 0
      })

      select(this.$refs.xAxis).call(this.xAxis)

      if (!this.notify) return
      selectAll(this.$refs.notifyPolygon)
        .data(this.notify)
        .attr('points', d => {
          const xScale = this.xScale(new Date(d[this.xKey] * 1000))
          return (
            xScale +
            ',0 ' +
            (xScale + this.polygonWidth / 2) +
            ',' +
            this.polygonWidth +
            ' ' +
            (xScale + this.polygonWidth) +
            ',0'
          )
        })
        .on('mouseover', this.mouseover)
        .on('mousemove', this.mousemoveNotify)
        .on('mouseleave', this.mouseleave)
    },
    addResizeListener () {
      window.addEventListener('resize', () => {
        const vm = this
        if (vm.windowWidth === window.innerWidth) return
        vm.redrawToggle = false
        setTimeout(() => {
          vm.redrawToggle = true
          vm.$nextTick(function () {
            vm.repaintChart()
          })
        }, 0)
      })
    },
    mousemoveStarted () {
      const vm = this
      select(vm.$refs.mousemoveRect)
        .transition()
        .duration(100)
        .attr('x', () => {
          return this.xScale(new Date(this.sliderDate))
        })
    },
    dragStarted () {
      const vm = this
      select(vm.$refs.mousemoveRect).call(
        drag().on('drag', function (event) {
          event.x >= vm.svgWidth
            ? (vm.touchX = vm.svgWidth)
            : event.x <= 0
              ? (vm.touchX = 0)
              : (vm.touchX = event.x)
        })
      )
    }
  },
  created () {},
  mounted () {
    this.$nextTick(function () {
      this.repaintChart()
      this.mousemoveStarted()
      this.dragStarted()
      this.addResizeListener()
    })
  }
}
</script>

<style lang="scss" scoped>
#tooltip {
  .tooltip {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    color: #fff;
    background-color: rgba(#000, 0.7);
    padding: 5px;
    min-width: 168px;
  }
}
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 1%;
  vertical-align: top;
  overflow: hidden;
  .svg {
    position: relative;
    width: 100%;
    overflow-x: auto;
    &::v-deep text {
      font-size: 14px;
    }
  }
}
</style>
